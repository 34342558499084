<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>{{ $t('pls_select_discount_rule') }}</v-card-title>
          <v-card-text>
            <v-select
              v-model="activeSelectDiscount"
              :items="discountRules"
              :label="$t('discount_rule_name')"
              item-text="name"
              item-value="id"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <template v-if="activeSelectDiscount">
      <v-row>
        <v-col>
          <v-card>
            <ik-data-table
              :entity-name="$t('timeSetting')"
              :filter="filter"
              :model="buffetModel"
              icon="mdi-account-child"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="12"
        >
          <v-card>
            <ik-data-table
              :entity-name="$t('discountDishList')"
              :filter="filter"
              :model="dishesDiscountListModel"
              :use-action="false"
              icon="mdi-account-child"
            />
          </v-card>
        </v-col>
        <!--      </v-row>-->
        <!--      </v-row>-->
      </v-row>
    </template>
  </v-container>
</template>

<script>

  import hillo from 'hillo'
  import { IkDataTable } from 'metaflow-js'
  import DishesDiscountList from '../../../model/dish/DishesDiscountList'
  import DishesDiscountTimeSpan from '../../../model/discount/DishesDiscountTimeSpan'

  export default {
    components: {
      IkDataTable,
    },
    data: function () {
      return ({
        load: [],
        activeSelectDiscount: null,
        discountRules: [],
        dishesDiscountListModel: DishesDiscountList,
        buffetModel: DishesDiscountTimeSpan,
      })
    },
    computed: {
      filter: function () {
        return this.activeSelectDiscount ? { id: this.activeSelectDiscount } : null
      },
    },
    async mounted () {
      this.discountRules = (await hillo.get('DiscountRule.php?op=getList')).content
    },

    methods: {},

  }
</script>
