import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import WeekDays from '@/model/common/WeekDays'
import DiscountRules from './DiscountRules'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
    header: false,
    formConfig: {
      requiredNew: false,
    },
  },

  discountRuleId: {
    displayName: 'discount_rule', // 折扣规则
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: DiscountRules.getList,
        itemText: 'name',
      },
    },
  },

  fromTime: {
    type: IKDataEntity.Types.Time,
    displayName: 'start_time', // 开始时间
  },
  toTime: {
    type: IKDataEntity.Types.Time,
    displayName: 'end_time', // 结束时间
  },
  dayOfWeek: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: WeekDays.getList(),
        itemText: 'name',
        multiple: true,
      },
    },

  },
}

const config = {

  load: async function (filter) {
    console.log(filter, 'i am loaded')

    return ((await hillo.get('Route.php?op=discountTimeList',
      ...filter,
    )).content)
  },

  add: async function (items) {
    // const dayOfWeeks = items.dayOfWeek
    const item = items
    console.log('item before', items)
    for (const dayOfWeek of items.dayOfWeek) {
      item.dayOfWeek = dayOfWeek
      console.log('item after', item)
      hillo.post('Route.php?op=discountTimeAdd', {
        ...item,
      })
    }
    return null
  },

  remove: function (id) {
    return hillo.post('Route.php?op=discountTimeDelete', { id: id })
  },

  edit: function (item) {
    return hillo.postWithUploadFile('Route.php?op=discountTimeUpdate', item, {
      showLoading: true,
    })
  },
}

export default IKDataEntity.ModelFactory(entity, config)
