import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import Category from './Category'
import CategoryType from './CategoryType'
import i18n from '../../i18n'

const entity = {
  dishesId: {
    type: IKDataEntity.Types.Integer,
    displayName: 'dish',
    formConfig: {
      requiredNew: false,
    },
    header: false,
  },

  ruleId: {
    header: false,
  },

  name: {
    displayName: 'name',
  },
  dishesCategoryId: {
    displayName: 'dishesCategoryId',
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: Category.getList,
        itemText: '_langsname',
      },
    },
  },
  dishesCategoryTypeId: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: CategoryType.getList,
        itemText: 'name',
      },
    },
  },

  enabled: {
    type: IKDataEntity.Types.Boolean,
    displayName: 'isActive',
  },

}

const config = {

  load: async function (filter) {
    console.log(filter, 'filter Dish')
    const id = filter[0].ruleId
    return ((await hillo.get('DiscountRule.php?op=getDishesListByDiscountRuleId',
      {
        lang: i18n.locale,
        id,
      },
    ))
      .content).map(d => {
      d.id = d.dishesId
      return d
    })
  },

  edit: async function (item) {
    console.log(item)
    return await hillo.post('DiscountRule.php?op=updateDishesDiscountRule',
      {
        dishesId: item.dishesId,
        discountRuleId: item.ruleId,
        enable: item.enabled ? 1 : 0,
      })
  },

}

export default IKDataEntity.ModelFactory(entity, config)
